<template>
  <div v-if="Object.keys(staff).length !== 0" >
    <div class="mb-1">
      <b-modal id="view-staff-modal" title="Support Staff Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <td>{{ staff.name }}</td>
              </tr>
              <tr>
                <th>Phone no.</th>
                <td>{{ staff.phone_no }}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{{ staff.address }}</td>
              </tr>
              <tr>
                <th>Father`s Name</th>
                <td>{{ staff.father_name }}</td>
              </tr>
              <tr>
                <th>Father`s  Phone no.</th>
                <td>{{ staff.father_phone_no }}</td>
              </tr>
              <tr>
                <th>Mother`s Name</th>
                <td>{{ staff.mother_name }}</td>
              </tr>
              <tr>
                <th>Mother`s Phone no.</th>
                <td>{{ staff.mother_phone_no ? staff.mother_phone_no : 'N/A' }}</td>
              </tr>
              <tr>
                <th>Joining Date</th>
                <td>{{ staff.joining_date }}</td>
              </tr>
              <tr>
                <th>Current Salary</th>
                <td>{{ staff.current_salary }}</td>
              </tr>
              <tr>
                <th>Working Area</th>
                <td>{{ staff.working_area }}</td>
              </tr>
              <tr>
                <th>Last Date of Increment</th>
                <td>{{ staff.last_date_of_increment }}</td>
              </tr>
              <tr>
                <th>Last Increment Amount</th>
                <td>{{ staff.last_increment_amount }}</td>
              </tr>
              <tr>
                <th>Hub</th>
                <td>{{ staff.hub.name }}</td>
              </tr>
              <tr>
                <th>Employee ID</th>
                <td>{{ staff.staff_employee_id }}</td>
              </tr>
              <tr>
                <th>Staff Type</th>
                <td>{{ staff.staff_type.name }}</td>
              </tr>
              <tr>
                <th>Staff Type</th>
                <td>
                  <template v-for="(staff_leave_type, index) in staff.staff_leave_types">
                      <span class="item"  :key="index">
                          {{ staff_leave_type.name }}
                          <span class="comma">
                              <br>
                          </span>
                      </span>
                  </template>
                </td>
              </tr>
              <tr>
                <th>Profile Picture</th>
                <td>
                    <span class="ant-badge">
                     <a :href="staff.profile_picture" target="_blank" download>
                       <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                            <img :src="staff.profile_picture_url" width="1em" height="1em" alt="">
                       </span>
                     </a>
                  </span>
                </td>
              </tr>
              <tr>
                <th>NID</th>
                <td>
                  <span class="ant-badge">
                     <a :href="staff.nid" target="_blank" download>
                       <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                            <img :src="staff.nid_url" width="1em" height="1em" alt="">
                       </span>
                     </a>
                  </span>
                </td>
              </tr>
              <tr>
                <th>Office ID Card</th>
                <td>
                  <span class="ant-badge">
                     <a :href="staff.office_id_image" target="_blank" download>
                       <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                            <img :src="staff.office_id_image_url" width="1em" height="1em" alt="">
                       </span>
                     </a>
                  </span>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: 'view',
  props: ['staff'],
  data() {
    return {}
  },
}
</script>
<style>
.item:last-child .comma {
  display: none;
}
</style>
